import { Controller } from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";

// Connects to data-controller="tabulator--order-analysis"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String
  }

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'local',
      paginationSize: 50,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'local',
      columns: this.columnsValue.map(column => {
        if (column.field === "total_quantity") {
          return {
            ...column,
            formatter: this.totalQuantityFormatter.bind(this),
          };
        }
        return column;
      }),
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
    });
  }

  totalQuantityFormatter(cell, formatterParams, onRendered) {
    const totalQuantity = cell.getValue();
    const expiredQuantity = cell.getRow().getData().total_expired_stock;
    const cellElement = document.createElement("div");
    cellElement.classList.add("flex", "justify-end", "items-center", "space-x-2");
    if (expiredQuantity > 0) {
      const icon = document.createElement("img");
      icon.src = "/assets/calendar.svg";
      icon.alt = "Calendar";
      icon.classList.add("mr-2", "w-4", "h-4", "tooltip");
      icon.setAttribute("title", `${cell.getRow().getData().total_expired_stock_message}`);
      cellElement.appendChild(icon);
    }
    const quantitySpan = document.createElement("span");
    quantitySpan.textContent = totalQuantity.display;
    cellElement.appendChild(quantitySpan);

    return cellElement;
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
