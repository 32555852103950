import { Controller } from "@hotwired/stimulus";
import Tabulator from "../../extensions/TabulatorExtended";

export default class extends Controller {
  static targets = ["hiddenInput", "table"];
  static values = {
    url: String,
    columns: Array,
    initialSelectedWarehouses: Array,
  };

  connect() {
    this.selectedWarehouses = new Map();
    this.groupCheckboxes = new Map();

    const initialWarehouses = this.initialSelectedWarehousesValue || [];
    initialWarehouses.forEach((warehouse) => {
      const warehouseId = warehouse.warehouse_id;
      this.selectedWarehouses.set(warehouseId, {
        id: warehouse.id,
        min_stock_quantity: warehouse.min_stock_quantity,
        max_stock_quantity: warehouse.max_stock_quantity,
        selected: true,
      });
    });

    this.updateHiddenInputs();
    this.initializeTabulator();
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
    }
  }

  initializeTabulator() {
    this.tabulator = new Tabulator(this.tableTarget, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      columns: this.constructColumns(),
      selectable: false,
      groupBy: "warehouse_type",
      groupStartOpen: false,
      groupHeader: this.groupHeaderFormatter.bind(this),
    });

    this.tabulator.on("tableBuilt", () => {
      this.syncSelections();
      this.updateHeaderCheckbox();
      this.syncGroupHeaders();
    });
  }

  constructColumns() {
    const columns = [
      {
        titleFormatter: this.headerCheckboxFormatter.bind(this),
        formatter: this.rowCheckboxFormatter.bind(this),
        cellClick: this.rowCheckboxClick.bind(this),
        headerSort: false,
        hozAlign: "center",
        width: 150,
        field: "select",
      },
    ];

    const processedColumns = this.columnsValue.map((column) => {
      const isQuantityField =
        column.field === "min_stock_quantity" ||
        column.field === "max_stock_quantity";

      if (column.formatter && isQuantityField) {
        const formatterFunction = this[column.formatter].bind(this);
        return { ...column, formatter: formatterFunction };
      }

      return column;
    });

    return columns.concat(processedColumns);
  }

  createCheckbox() {
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    return checkbox;
  }

  getWarehouseId(data) {
    return data.warehouse_id || data.id;
  }

  selectWarehouse(warehouseId) {
    if (!this.selectedWarehouses.has(warehouseId)) {
      this.selectedWarehouses.set(warehouseId, {
        min_stock_quantity: null,
        max_stock_quantity: null,
        selected: true,
      });
    } else {
      const warehouseData = this.selectedWarehouses.get(warehouseId);
      warehouseData.selected = true;
    }
  }

  deselectWarehouse(warehouseId) {
    if (this.selectedWarehouses.has(warehouseId)) {
      const warehouseData = this.selectedWarehouses.get(warehouseId);
      warehouseData.selected = false;
    }
  }

  toggleWarehouseSelection(warehouseId) {
    if (this.selectedWarehouses.has(warehouseId)) {
      const warehouseData = this.selectedWarehouses.get(warehouseId);
      if (warehouseData.selected) {
        this.deselectWarehouse(warehouseId);
      } else {
        this.selectWarehouse(warehouseId);
      }
    } else {
      this.selectWarehouse(warehouseId);
    }
  }

  headerCheckboxFormatter() {
    const checkbox = this.createCheckbox();
    checkbox.addEventListener("change", () => {
      const checked = checkbox.checked;
      const rows = this.tabulator.getRows();

      rows.forEach((row) => {
        const data = row.getData();
        const warehouseId = this.getWarehouseId(data);

        if (checked) {
          this.selectWarehouse(warehouseId);
        } else {
          this.deselectWarehouse(warehouseId);
        }

        this.updateRowCheckbox(row.getCell("select"), warehouseId);
      });

      this.updateHiddenInputs();
      this.syncSelections();
      this.syncGroupHeaders();
    });

    this.headerCheckbox = checkbox;
    return checkbox;
  }

  rowCheckboxFormatter(cell) {
    const data = cell.getRow().getData();
    const warehouseId = this.getWarehouseId(data);
    const checkbox = this.createCheckbox();

    const warehouseData = this.selectedWarehouses.get(warehouseId);
    if (warehouseData && warehouseData.selected) {
      checkbox.checked = true;
    } else {
      checkbox.checked = false;
    }

    return checkbox;
  }

  rowCheckboxClick(event, cell) {
    event.stopPropagation();

    const data = cell.getRow().getData();
    const warehouseId = this.getWarehouseId(data);

    this.toggleWarehouseSelection(warehouseId);
    this.updateRowCheckbox(cell, warehouseId);
    this.updateHiddenInputs();
    this.syncSelections();
    this.updateHeaderCheckbox();
    this.syncGroupHeaders();
  }

  updateRowCheckbox(cell, warehouseId) {
    const checkbox = cell.getElement().querySelector('input[type="checkbox"]');
    if (checkbox) {
      const warehouseData = this.selectedWarehouses.get(warehouseId);
      if (warehouseData && warehouseData.selected) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    }
  }

  minStockQuantityFormatter(cell) {
    const data = cell.getRow().getData();
    const warehouseId = this.getWarehouseId(data);
    const input = document.createElement("input");

    input.type = "number";
    input.min = 0;

    const warehouseData = this.selectedWarehouses.get(warehouseId);
    if (warehouseData && warehouseData.min_stock_quantity !== null) {
      input.value = warehouseData.min_stock_quantity;
    } else {
      input.value = "";
    }

    if (warehouseData && warehouseData.selected) {
      input.disabled = false;
    } else {
      input.disabled = true;
    }

    input.addEventListener("change", (event) => {
      const value = event.target.value;
      this.updateWarehouseQuantities(warehouseId, value, null);
    });

    return input;
  }

  maxStockQuantityFormatter(cell) {
    const data = cell.getRow().getData();
    const warehouseId = this.getWarehouseId(data);
    const input = document.createElement("input");

    input.type = "number";
    input.min = 0;

    const warehouseData = this.selectedWarehouses.get(warehouseId);
    if (warehouseData && warehouseData.max_stock_quantity !== null) {
      input.value = warehouseData.max_stock_quantity;
    } else {
      input.value = "";
    }

    if (warehouseData && warehouseData.selected) {
      input.disabled = false;
    } else {
      input.disabled = true;
    }

    input.addEventListener("change", (event) => {
      const value = event.target.value;
      this.updateWarehouseQuantities(warehouseId, null, value);
    });

    return input;
  }

  updateWarehouseQuantities(warehouseId, minQuantity, maxQuantity) {
    if (this.selectedWarehouses.has(warehouseId)) {
      const warehouseData = this.selectedWarehouses.get(warehouseId);

      if (minQuantity !== null) {
        warehouseData.min_stock_quantity = parseInt(minQuantity) || null;
      }

      if (maxQuantity !== null) {
        warehouseData.max_stock_quantity = parseInt(maxQuantity) || null;
      }

      this.updateHiddenInputs();
    }
  }

  updateHiddenInputs() {
    this.hiddenInputTarget.innerHTML = "";
    let index = 0;

    this.selectedWarehouses.forEach((warehouseData, warehouseId) => {
      if (warehouseData.selected) {
        if (warehouseData.id) {
          this.createHiddenInput(
            `stock_card[stock_card_warehouses_attributes][${index}][id]`,
            warehouseData.id
          );
        }

        this.createHiddenInput(
          `stock_card[stock_card_warehouses_attributes][${index}][warehouse_id]`,
          warehouseId
        );

        this.createHiddenInput(
          `stock_card[stock_card_warehouses_attributes][${index}][min_stock_quantity]`,
          warehouseData.min_stock_quantity !== null
            ? warehouseData.min_stock_quantity
            : ""
        );

        this.createHiddenInput(
          `stock_card[stock_card_warehouses_attributes][${index}][max_stock_quantity]`,
          warehouseData.max_stock_quantity !== null
            ? warehouseData.max_stock_quantity
            : ""
        );
      } else if (warehouseData.id) {
        this.createHiddenInput(
          `stock_card[stock_card_warehouses_attributes][${index}][id]`,
          warehouseData.id
        );
        this.createHiddenInput(
          `stock_card[stock_card_warehouses_attributes][${index}][warehouse_id]`,
          warehouseId
        );
        this.createHiddenInput(
          `stock_card[stock_card_warehouses_attributes][${index}][_destroy]`,
          "1"
        );
      }

      index += 1;
    });

    if (this.selectedWarehouses.size === 0) {
      this.createHiddenInput(
        "stock_card[stock_card_warehouses_attributes][]",
        ""
      );
    }
  }

  createHiddenInput(name, value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    this.hiddenInputTarget.appendChild(input);
  }

  syncSelections() {
    const rows = this.tabulator.getRows();

    rows.forEach((row) => {
      const data = row.getData();
      const warehouseId = this.getWarehouseId(data);
      const warehouseData = this.selectedWarehouses.get(warehouseId);
      const isSelected = warehouseData && warehouseData.selected;

      this.updateRowCheckbox(row.getCell("select"), warehouseId);
      this.updateQuantityInputs(row, warehouseId, isSelected);
    });

    this.updateHeaderCheckbox();
    this.syncGroupHeaders();
  }

  updateQuantityInputs(row, warehouseId, isSelected) {
    const warehouseData = this.selectedWarehouses.get(warehouseId);

    const minCell = row.getCell("min_stock_quantity");
    const maxCell = row.getCell("max_stock_quantity");

    if (minCell) {
      const minInput = minCell.getElement().querySelector("input");
      if (minInput) {
        minInput.disabled = !isSelected;
        if (warehouseData && warehouseData.min_stock_quantity !== null) {
          minInput.value = warehouseData.min_stock_quantity;
        } else {
          minInput.value = "";
        }
      }
    }

    if (maxCell) {
      const maxInput = maxCell.getElement().querySelector("input");
      if (maxInput) {
        maxInput.disabled = !isSelected;
        if (warehouseData && warehouseData.max_stock_quantity !== null) {
          maxInput.value = warehouseData.max_stock_quantity;
        } else {
          maxInput.value = "";
        }
      }
    }
  }

  updateHeaderCheckbox() {
    const totalRows = this.tabulator.getRows().length;
    const selectedRows = Array.from(this.selectedWarehouses.values()).filter(
      (warehouseData) => warehouseData.selected
    ).length;

    if (this.headerCheckbox) {
      if (selectedRows === totalRows && totalRows > 0) {
        this.headerCheckbox.checked = true;
        this.headerCheckbox.indeterminate = false;
      } else if (selectedRows > 0 && selectedRows < totalRows) {
        this.headerCheckbox.checked = false;
        this.headerCheckbox.indeterminate = true;
      } else {
        this.headerCheckbox.checked = false;
        this.headerCheckbox.indeterminate = false;
      }
    }
  }

  groupHeaderFormatter(value, count, data, group) {
    const container = document.createElement("span");
    const checkbox = this.createCheckbox();
    const groupRows = group.getRows();
    const groupIds = groupRows.map((row) =>
      this.getWarehouseId(row.getData())
    );

    const selectedInGroup = groupIds.filter((id) => {
      const warehouseData = this.selectedWarehouses.get(id);
      return warehouseData && warehouseData.selected;
    });

    if (selectedInGroup.length === groupIds.length) {
      checkbox.checked = true;
      checkbox.indeterminate = false;
    } else if (selectedInGroup.length > 0) {
      checkbox.checked = false;
      checkbox.indeterminate = true;
    } else {
      checkbox.checked = false;
      checkbox.indeterminate = false;
    }

    checkbox.addEventListener("change", (event) => {
      event.stopPropagation();
      const checked = checkbox.checked;

      groupRows.forEach((row) => {
        const data = row.getData();
        const warehouseId = this.getWarehouseId(data);

        if (checked) {
          this.selectWarehouse(warehouseId);
        } else {
          this.deselectWarehouse(warehouseId);
        }

        this.updateRowCheckbox(row.getCell("select"), warehouseId);
      });

      this.updateHiddenInputs();
      this.syncSelections();
      this.updateHeaderCheckbox();
    });

    container.appendChild(checkbox);
    this.groupCheckboxes.set(group.getKey(), checkbox);

    const label = document.createElement("span");
    label.textContent = `${value} (${count})`;
    label.style.cursor = "pointer";
    label.addEventListener("click", (event) => {
      event.stopPropagation();
      group.toggle();
    });

    container.appendChild(label);
    return container;
  }

  syncGroupHeaders() {
    const groups = this.tabulator.getGroups();

    groups.forEach((group) => {
      const groupRows = group.getRows();
      const groupIds = groupRows.map((row) =>
        this.getWarehouseId(row.getData())
      );
      const checkbox = this.groupCheckboxes.get(group.getKey());

      if (checkbox) {
        const selectedInGroup = groupIds.filter((id) => {
          const warehouseData = this.selectedWarehouses.get(id);
          return warehouseData && warehouseData.selected;
        });

        if (selectedInGroup.length === groupIds.length) {
          checkbox.checked = true;
          checkbox.indeterminate = false;
        } else if (selectedInGroup.length > 0) {
          checkbox.checked = false;
          checkbox.indeterminate = true;
        } else {
          checkbox.checked = false;
          checkbox.indeterminate = false;
        }
      }
    });
  }

  toggleAll() {
    const groups = this.tabulator.getGroups();
    groups.forEach((group) => {
      group.toggle();
    });
  }
}
